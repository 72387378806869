.ft-a1 {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 48px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 39px;
  background: #1a5345;
  margin-top: 90px;
  color: #fff;
}

.c-a-s {
  text-align: center;
  font-feature-settings: "cv01" on, "cv03" on, "cv04" on;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 32px */
  letter-spacing: -1.28px;
}
.t-y-t {
  text-align: center;
  font-feature-settings: "cv01" on, "cv03" on, "cv04" on;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 26.1px */
}
.l-e {
  width: 95vw;
  align-self: center;
  background: #242826;
  height: 1px;
}
.c-s-t-y {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  align-self: stretch;
}

.f-t-l-l-c {
  display: flex;
  padding: 0px 3px;
  width: 90vw;
  justify-content: space-between;
  align-items: center;
}
.f-t-l-l-c img {
  width: auto;
  height: 55px;
  border-radius: 8px;
}
.c-s-t-y span {
  font-feature-settings: "cv03" on, "cv04" on;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.c-u-n {
  display: flex;
  gap: 12px;
}
.c-u-n img {
  width: 28px;
  height: 28px;
}
.c-o {
  font-size: 18px;
  font-weight: bolder;
}
label {
  font-size: 17px;
}
@media (min-width: 370px) and (max-width: 579px) {
  .ft-a1 span {
    font-size: 16px;
  }
  .t-y-t {
    font-size: 16px;
  }
  .c-a-s {
    font-size: 28px;
  }
}
@media (min-width: 300px) and (max-width: 369px) {
  label {
    font-size: 14px;
  }

  .c-a-s {
    font-size: 25px;
  }
  .t-y-t {
    font-size: 15px;
  }
}

@media (min-width: 3500px) {
  .ft-a1 {
    padding: 48px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 39px;
    margin-top: 90px;
  }

  .c-a-s {
    text-align: center;
    font-size: 42px;
    font-weight: 600;
  }
  .t-y-t {
    text-align: center;
    font-size: 38px;
    font-weight: 600;
  }
  .l-e {
    width: 95%;
    align-self: center;
    height: 3px;
  }
  .c-s-t-y {
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    align-self: stretch;
  }

  .f-t-l-l-c {
    display: flex;
    padding: 0px 3px;
    width: 93%;
    justify-content: space-between;
    align-items: center;
  }
  .f-t-l-l-c img {
    width: auto;
    height: 160px;
    border-radius: 8px;
  }
  .c-s-t-y span {
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
  }

  .c-u-n {
    display: flex;
    gap: 42px;
  }
  .c-u-n img {
    width: 88px;
    height: 88px;
  }
  .c-o {
    font-size: 30px;
    font-weight: bolder;
  }
  label {
    font-size: 36px;
  }
}
