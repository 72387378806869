/*header-section*/

.header {
  height: 100px;
  display: flex;
  padding: 16px 214px 16px 24px;
  align-items: center;
  position: relative;
  background-color: #1a5345;
}

.lctn img {
  width: 170px;
}

.lks-v {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.lks {
  width: 500px;
  flex-shrink: 0;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  justify-content: center;
  gap: 28px;
  border-radius: 28px;
  border: 0.5px solid #fff;
  background: #1a5345;
  position: relative;
}

.lks a {
  padding: 4px 8px;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #1a5345;
}

.dropdown-toggle {
  display: none;
}
.headsect {
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.hr-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;
}

.hr-sec .min {
  margin: 0px;
  width: 95vw;
  text-align: center;
  font-feature-settings: "cv01" on, "cv03" on, "cv04" on;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.min-ph {
  width: 508px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.cta a {
  padding: 12px 42px;
  border-radius: 12px;
  background: #1a5345;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
.cta a:hover {
  background: #316155;
}
.il-v {
  display: flex;
  justify-content: center;
  align-items: center;
}
.il-v img {
  max-width: 1500px;
  width: 80vw;
  border-radius: 12px;
  object-fit: contain;
}
.msn-sect {
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
.co-sect {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  background-color: #1a5345;
  color: #fff;
}
.pr-sect {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
}
.rv-sect {
  margin-top: 90px;
  display: flex;
  justify-content: center;
}
.rv-sect span {
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.f-st {
  width: 100%;
}
.a-1-f-l {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}
@media (max-width: 910px) and (min-width: 800px) {
  .header {
    padding: 12px 196px 12px 14px;
    gap: 20px;
  }

  .lks {
    max-width: 450px;
    padding: 10px 16px;
    gap: 10px;
  }
  .lks a {
    font-size: 17px;
  }
}

@media (max-width: 799px) {
  /*header-section*/
  .header {
    padding: 12px 0px 12px 18px;
    gap: 0px;
  }
  .il-v img {
    width: 85%;
  }
  .lks {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 150%;
    left: -205px;
    right: 0px;
    width: auto;
    box-sizing: border-box;
    max-width: 1050px;
    background: #1a5345;
    z-index: 1;
    padding: 10px 22px;
    border-radius: 0%;
    border: none;
  }

  .lks.show {
    display: flex;
    align-items: flex-start;
  }
  .dropdown-toggle {
    background: #1a5345;
    margin-right: 22px;
    border: none;
    display: block;
    cursor: pointer;
  }
  .dropdown-toggle img {
    height: 28px;
    width: 28px;
  }

  .lks-v {
    justify-content: flex-end;
  }
  /* hero-section*/
  .min-ph {
    width: 350px;
    font-size: 17px;
  }
  .hr-sec .min {
    font-size: 28px;
  }
}

@media (max-width: 350px) {
  .min-ph {
    width: 320px;
    font-size: 15px;
  }
  .hr-sec .min {
    font-size: 25px;
  }
}

@media (min-width: 3500px) {
  body {
    max-width: 3500px;
  }

  html {
    display: flex;
    justify-content: center;
  }
  .header {
    max-width: 3500px;
    height: 350px;
    display: flex;
    padding: 20px 170px 20px 40px;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .il-v img {
    max-width: 80%;
  }
  .lctn img {
    width: 470px;
  }
  .lks {
    width: 2000px;
    flex-shrink: 0;
    display: flex;
    padding: 32px 48px;
    align-items: center;
    justify-content: center;
    gap: 120px;
    border-radius: 100px;
    position: relative;
  }

  .lks a {
    padding: 8px 10px;
    text-decoration: none;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .hr-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 94px;
  }

  .hr-sec .min {
    font-size: 90px;
  }
  .min-ph {
    width: 1208px;
    text-align: center;
    font-size: 60px;
    font-weight: 500;
  }
  .cta a {
    padding: 32px 52px;
    border-radius: 30px;
    font-size: 65px;
  }
  .rv-sect span {
    font-size: 60px;
  }
}
