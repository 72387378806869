@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  background: #ffffff;
  color: #4b4f4c;
  padding: 0px;
  margin: 0px;
  font-family: "Roboto", Arial;
  -webkit-tap-highlight-color: rgb(131, 51, 100, 0);
}
button {
  outline: none;
  box-shadow: none;
  border: none;
}
body::-webkit-scrollbar {
  display: none;
  opacity: 0;
}
a {
  text-decoration: none;
}
p {
  margin: 0;
}
html {
  scroll-behavior: smooth;
  background-color: grey;
  transition: background-color 0.3s;
}

@-moz-document url-prefix() {
  html,
  body {
    scrollbar-width: none;
  }
}
