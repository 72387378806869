.msn {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
}
.msn-pm {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.msn .pm {
  width: 280px;
  margin-bottom: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.msn .pm1 {
  width: 280px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.crd {
  display: flex;
  padding: 20px;
  width: 250px;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  background: #1a5345;
  color: #fff;
}
.crd-pm {
  font-size: 18px;
  font-weight: 700;
}
.crd-pm1 {
  font-weight: 500;
  font-size: 14px;
}
.crd img {
  width: 30px;
  height: 30px;
}
.msn-crd {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
}
.dw-lf {
  margin-top: 100px;
}
.dw-rg {
  margin-top: 40px;
}
.msn-crd-all {
  display: flex;
  flex-direction: row;
  gap: 70px;
}

@media (max-width: 970px) {
  .msn {
    display: flex;
    justify-content: center;
  }
  .msn .pm1 {
    width: 280px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .crd {
    padding: 15px;
    width: 200px;
    height: 130px;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    background: #1a5345;
    color: #fff;
  }
  .msn-crd-all {
    display: flex;
    flex-direction: row;
    gap: 70px;
  }
}
@media (max-width: 920px) {
  .crd-pm {
    font-size: 18px;
    font-weight: 700;
  }
  .crd-pm1 {
    font-weight: 400;
    font-size: 14px;
  }
  .crd img {
    width: 26px;
    height: 326px;
  }
  .msn-crd-all {
    gap: 35px;
  }
  .msn-crd {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }
  .dw-lf {
    margin-top: 80px;
  }
  .dw-rg {
    margin-top: 40px;
  }
  .msn .pm {
    width: 210px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
  }
  .msn .pm1 {
    width: 210px;
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: 750px) {
  .crd {
    display: flex;
    padding: 20px;
    width: 180px;
    height: 120px;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
  }
  .crd-pm {
    font-size: 18px;
    font-weight: 700;
  }
  .crd-pm1 {
    font-weight: 400;
    font-size: 14px;
  }
  .crd img {
    width: 30px;
    height: 30px;
  }
  .msn-crd {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  .msn .pm {
    width: 190px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
  }
  .msn .pm1 {
    width: 180px;
    font-size: 14px;
    font-weight: 400;
  }
  .msn {
    gap: 15px;
  }
}
@media (max-width: 720px) {
  .msn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .msn-crd-all {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .msn-crd {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .dw-lf {
    margin-top: 50px;
  }
  .dw-rg {
    margin-top: 15px;
  }
  .msn .pm1 {
    width: 400px;
    font-size: 14px;
    font-weight: 400;
  }
  .msn .pm {
    width: 400px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
  }
}
@media (max-width: 500px) {
  .msn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .msn-crd-all {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .msn-crd {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .dw-lf {
    margin-top: 50px;
  }
  .dw-rg {
    margin-top: 15px;
  }
  .msn .pm1 {
    width: 400px;
    font-size: 14px;
    font-weight: 400;
  }
  .msn .pm {
    width: 400px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
  }
  .crd {
    display: flex;
    padding: 10px;
    width: 180px;
    height: 120px;
    gap: 8px;
    align-self: stretch;
    border-radius: 16px;
  }
  .crd-pm {
    font-size: 16px;
    font-weight: 700;
  }
  .crd-pm1 {
    font-weight: 400;
    font-size: 12px;
  }
  .crd img {
    width: 26px;
    height: 26px;
  }
}

@media (max-width: 420px) {
  .msn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .msn-crd-all {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .msn-crd {
    gap: 30px;
    display: flex;
    flex-direction: column;
  }
  .dw-lf {
    margin-top: 50px;
  }
  .dw-rg {
    margin-top: 0px;
  }
  .msn .pm1 {
    width: 340px;
    font-size: 18px;
    font-weight: 400;
  }
  .msn .pm {
    width: 340px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
  }
  .crd {
    display: flex;
    padding: 28px;
    width: 280px;
    height: 150px;
    gap: 14px;
    align-self: stretch;
    border-radius: 16px;
  }
  .crd-pm {
    font-size: 20x;
    font-weight: 700;
  }
  .crd-pm1 {
    font-weight: 400;
    font-size: 17px;
  }
  .crd img {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 350px) {
  .msn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .msn-crd-all {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .msn-crd {
    gap: 30px;
    display: flex;
    flex-direction: column;
  }
  .dw-lf {
    margin-top: 50px;
  }
  .dw-rg {
    margin-top: 0px;
  }
  .msn .pm1 {
    width: 300px;
    font-size: 18px;
    font-weight: 400;
  }
  .msn .pm {
    width: 300px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
  }
  .crd {
    display: flex;
    padding: 15px;
    width: 250px;
    height: 150px;
    gap: 14px;
    align-self: stretch;
    border-radius: 16px;
  }
  .crd-pm {
    font-size: 20x;
    font-weight: 700;
  }
  .crd-pm1 {
    font-weight: 400;
    font-size: 17px;
  }
  .crd img {
    width: 36px;
    height: 36px;
  }
}

@media (min-width: 3500px) {
  .msn {
    margin-top: 90px;
    display: flex;
    gap: 40px;
    justify-content: center;
  }

  .msn .pm {
    width: 1080px;
    margin-bottom: 10px;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .msn .pm1 {
    width: 1080px;
    font-size: 58px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .crd {
    display: flex;
    padding: 40px;
    width: 630px;
    height: 530px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    align-self: stretch;
    border-radius: 36px;
  }
  .crd-pm {
    font-size: 56px;
    font-weight: 700;
  }
  .crd-pm1 {
    font-weight: 400;
    font-size: 48px;
  }
  .crd img {
    width: 100px;
    height: 100px;
  }
  .msn-crd {
    display: flex;
    flex-direction: column;
    row-gap: 180px;
  }
  .dw-lf {
    margin-top: 150px;
  }
  .dw-rg {
    margin-top: 60px;
  }
  .msn-crd-all {
    display: flex;
    flex-direction: row;
    gap: 180px;
  }
}
