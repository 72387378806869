.cnt-pr {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.pr-v {
  padding: 10px;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.pr-v-kb {
  margin-top: 30px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pr-b-v {
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
  background: #000;
}
.pr-b-v button {
  display: flex;
  background: #242826;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #fff;
  border-radius: 12px;
  border: none;
  font-size: 14px;
  font-weight: 600;
}
.pr-b-v button:hover {
  background-color: #626864;
}
.pr-b-v button.active {
  background-color: #1a5345;
}
.pr-pl {
  display: flex;
  flex-direction: row;
}
.pl-sc {
  width: auto;
  display: flex;
}
.pl-sc::-webkit-scrollbar {
  display: none;
}
.pl-sc::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.pr {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  padding: 0px 10px;
}
.pr-ac {
  font-weight: 600;
  font-size: 18px;
}
.pl {
  display: flex;
  gap: 30px;
}
.pl-crd {
  width: 280px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: #0d100e;
  padding: 24px;
  gap: 20px;
  color: #fff;
}
.pl-crd-m {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: end;
}
.pl-crd-m img {
  width: 48px;
  height: 48px;
}
.pl-crd-m p {
  display: flex;
  justify-content: flex-end;
  width: 130px;
  font-weight: 600;
  font-size: 18px;
}

.pl-crd-m span {
  font-size: 14px;
  color: #8b998f;
  font-weight: 400;
  padding-top: 4px;
}
.pl-ty {
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 10px;
}
.ty {
  font-weight: 600;
  font-size: 16px;
}
.al-fe button {
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  background: #42e06e;
}
.al-fe button:hover {
  background-color: #23cc53;
}
.al-fe {
  border-radius: 16px;
  border: 0.3px solid #8b998f;
  background: #fff;
  padding: 10px;
  color: #0d100e;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.fe1l {
  font-weight: 700;
}
.al-fe-0 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.fe {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  padding: 0px 8px;
}
.fe p {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fe img {
  width: 22px;
  height: 22px;
}

@media (min-width: 768px) and (max-width: 920px) {
  .pr-pl {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
  .pr-pl-inv {
    flex-direction: column-reverse;
  }
  .pl {
    gap: 30px;
  }
}

@media (min-width: 650px) and (max-width: 767px) {
  .pl-sc {
    display: flex;
    width: 500px;
    overflow-x: scroll;
    padding: 5px;
  }
  .pr-pl {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
  .pr-pl-inv {
    flex-direction: column-reverse;
  }
  .pl {
    gap: 40px;
    display: flex;
  }
  .pr-v-kb {
    gap: 50px;
  }
}

@media (min-width: 450px) and (max-width: 649px) {
  .pl-sc {
    display: flex;
    width: 420px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding: 5px;
  }

  .pl-sc:scroll {
    transition: transform 0.3s ease;
  }

  .pr-pl {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
  .pr-pl-inv {
    flex-direction: column-reverse;
  }
  .pl {
    gap: 30px;
    display: flex;
  }
  .pr-v-kb {
    gap: 19px;
  }
  .pr-b-v button {
    padding: 10px;
    gap: 5px;
    border-radius: 8px;
    font-size: 12px;
  }
  .pr-b-v {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #000;
  }
}
@media (max-width: 460px) {
  .pr-v-kb {
    gap: 10px;
  }
  .pr-b-v {
    display: flex;
    padding: 10px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #000;
  }
  .pr-b-v button {
    padding: 10px;
    gap: 5px;
    border-radius: 8px;
    font-size: 10px;
  }
}
@media (min-width: 360px) and (max-width: 449px) {
  .pr-v {
    padding: 0px;
    font-size: 16px;
  }
  .pl-crd {
    padding: 15px;
  }

  .pl-sc:scroll {
    transition: transform 0.3s ease;
  }
  .pl {
    gap: 15px;
    display: flex;
  }
  .pl-sc {
    display: flex;
    width: 90vw;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding: 10px;
  }
  .pr-pl {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .pr-pl-inv {
    flex-direction: column-reverse;
  }
  .pr-v-kb {
    gap: 10px;
  }
  .pr-b-v {
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #000;
  }
  .pr-b-v button {
    padding: 8px;
    gap: 5px;
    border-radius: 8px;
    font-size: 10px;
  }
  .pr-v-kb {
    gap: 10px;
  }
}
@media (max-width: 359px) {
  .pr-v {
    padding: 0px;
    font-size: 13px;
    font-weight: 700;
  }
  .pl-sc:scroll {
    /* Optional: Add transition effects if desired */
    transition: transform 0.3s ease;
  }
  .pl-sc {
    display: flex;
    width: 95vw;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding: 5px;
  }
  .pr-pl {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
  .pr-pl-inv {
    flex-direction: column-reverse;
  }
  .pr-v-kb {
    gap: 10px;
  }
  .pr-b-v {
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 9px;
    background: #000;
  }
  .pr-b-v button {
    padding: 8px;
    gap: 5px;
    border-radius: 5px;
    font-size: 10px;
  }
  .pr-v-kb {
    gap: 10px;
  }
}

@media (min-width: 3500px) {
  .cnt-pr {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .pr-v {
    padding: 10px;
    font-size: 65px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .pr-v-kb {
    width: 2000px;
    margin-top: 30px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pr-b-v {
    display: flex;
    padding: 30px;
    align-items: center;
    gap: 30px;
    border-radius: 36px;
  }
  .pr-b-v button {
    display: flex;
    background: #242826;
    padding: 22px;
    justify-content: center;
    align-items: center;
    gap: 22px;
    color: #fff;
    border-radius: 20px;
    border: none;
    font-size: 40px;
    font-weight: 600;
  }
  .pr-b-v button:hover {
    background-color: #626864;
  }
  .pr-b-v button.active {
    background-color: #1a5345;
  }
  .pr-pl {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }
  .pl-sc {
    width: auto;
    display: flex;
  }
  .pl-sc::-webkit-scrollbar {
    display: none;
  }
  .pl-sc::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 1px;
  }
  .pr {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    padding: 0px 10px;
  }
  .pr-ac {
    font-weight: 600;
    font-size: 48px;
  }
  .pr p {
    font-size: 40px;
  }
  .pl {
    display: flex;
    gap: 200px;
  }
  .pl-crd {
    width: 550px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 34px;
    gap: 20px;
  }
  .pl-crd-m {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
  }
  .pl-crd-m img {
    width: 100px;
    height: 100px;
  }
  .pl-crd-m p {
    display: flex;
    justify-content: flex-end;
    width: 370px;
    font-weight: 600;
    font-size: 50px;
  }

  .pl-crd-m span {
    font-size: 35px;
    padding-top: 12px;
  }
  .pl-ty {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .ty {
    font-weight: 600;
    font-size: 56px;
  }
  .ty1 {
    font-size: 35px;
    width: 500px;
  }
  .al-fe button {
    padding: 20px 28px;
    border-radius: 18px;
    font-size: 40px;
  }

  .al-fe {
    border-radius: 16px;
    padding: 10px;
    gap: 14px;
  }
  .fe1l {
    font-size: 36px;
    font-weight: 700;
  }
  .al-fe-0 {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .fe {
    gap: 7px;
    align-items: center;
    padding: 0px 3px;
  }
  .fe p {
    color: #000;
    font-size: 28px;
  }
  .fe img {
    width: 42px;
    height: 42px;
  }
}

@-moz-document url-prefix() {
  .pl-sc {
    scrollbar-width: none;
  }
}
