.sp-nr {
  display: flex;
  gap: 160px;
  align-items: center;
  margin-top: 10px;
  padding: 10px 15px;
}
.sp-nr img {
  width: 350px;
  height: 350px;
}
.sp-co {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 370px;
  gap: 28px;
}

.sp-co .co {
  font-size: 22px;
  font-weight: 700;
}
.sp-co .co1 {
  font-size: 18px;
  font-weight: 400;
}

@media (min-width: 768px) and (max-width: 940px) {
  .sp-nr {
    display: flex;
    gap: 20px;
    padding: 0px 10px;
  }
  .sp-nr img {
    width: 335px;
    height: 335px;
  }
  .sp-co {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    gap: auto;
  }
}
@media (max-width: 767px) {
  .sp-nr {
    gap: 20px;
    padding: 0px 19.8px;
  }
  .sp-nr img {
    width: 250px;
    height: 250px;
  }
  .sp-co {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    gap: 13px;
  }
  .sp-co .co {
    font-size: 21px;
    font-weight: 700;
  }
  .sp-co .co1 {
    font-size: 17px;
    font-weight: 400;
  }
}

@media (max-width: 676px) and (min-width: 630px) {
  .sp-nr {
    gap: 15px;
    padding: 0px 20px;
  }
  .sp-nr img {
    width: 210px;
    height: 210px;
  }
  .sp-co {
    width: 350px;
    gap: auto;
  }
  .sp-co .co {
    font-size: 20px;
  }
  .sp-co .co1 {
    font-size: 16px;
  }
}

@media (min-width: 570px) and (max-width: 629px) {
  .sp-nr {
    gap: 15px;
    padding: 0px 10px;
  }
  .sp-nr img {
    width: 170px;
    height: 170px;
  }
  .sp-co {
    width: 350px;
    gap: 15px;
  }
  .sp-co .co {
    font-size: 20px;
    font-weight: 700;
  }
  .sp-co .co1 {
    font-size: 16px;
    font-weight: 400;
  }
}

@media (min-width: 450px) and (max-width: 569px) {
  .sp-nr {
    margin-top: 30px;
    gap: 5px;
    padding: 10px 5px;
  }
  .sp-nr img {
    width: 135px;
    height: 135px;
  }
  .sp-co {
    width: 300px;
    gap: auto;
  }
  .sp-co .co {
    font-size: 18px;
    font-weight: 700;
  }
  .sp-co .co1 {
    font-size: 14px;
    font-weight: 400;
  }
}

@media (min-width: 300px) and (max-width: 449px) {
  .sp-nr {
    flex-direction: column;
    margin-top: 30px;
    gap: 20px;
    padding: 10px 0px;
  }
  .sp-nr img {
    width: 255px;
    height: 255px;
  }
  .sp-co {
    width: 300px;
    gap: 15px;
  }
  .sp-co .co {
    font-size: 20px;
    font-weight: 700;
  }
  .sp-co .co1 {
    font-size: 16px;
    font-weight: 400;
  }
}

@media (min-width: 3500px) {
  .sp-nr {
    display: flex;
    gap: 160px;
    align-items: center;
    margin-top: 50px;
    padding: 10px 15px;
  }
  .sp-nr img {
    width: 1200px;
    height: 1200px;
  }
  .sp-co {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1280px;
    gap: 50px;
  }
  .sp-co .co {
    font-size: 60px;
    font-weight: 700;
  }
  .sp-co .co1 {
    font-size: 55px;
    font-weight: 400;
  }
}
