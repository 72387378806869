.dark-mode-toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 28px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  border: 1px solid #42e06e;
  background-color: #1a5345;
  outline: none;
  box-shadow: none;
}
html.dark-mode {
  background-color: #020202;
}
body.dark-mode {
  background: #242826;
  color: #ffffff;
}

.dark-mode-toggle {
  outline: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: none;
  background-color: #42e06e;
  border-radius: 50%;
  position: absolute;
  transition: transform 0.8s ease;
}
.icon.toggle-right,
.dark-mode-toggle.toggle-left {
  transform: translateX(-15px);
}
.icon.toggle-left,
.dark-mode-toggle.toggle-right {
  transform: translateX(15px);
}
.icon.toggle-left,
.icon.toggle-right {
  color: rgba(235, 230, 230, 0.25);
}
.icon {
  transition: color 0.3s ease;
  pointer-events: none;
}

.icon-light {
  color: #ffffff;
}

.icon-dark {
  color: rgba(22, 21, 21, 1);
}

@media (min-width: 3500px) {
  .dark-mode-toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 92px;
    border-radius: 100px;

    border: 2px solid #42e06e;
    outline: none;
    box-shadow: none;
  }

  body.dark-mode {
    background: #242826;
    color: #ffffff;
  }

  .dark-mode-toggle {
    outline: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 88px;
    border: none;
    border-radius: 50%;
    position: absolute;
  }
  .icon {
    height: 50px;
    width: 50px;
  }
  .icon.toggle-right,
  .dark-mode-toggle.toggle-left {
    transform: translateX(-55px);
  }
  .icon.toggle-left,
  .dark-mode-toggle.toggle-right {
    transform: translateX(55px);
  }
}
