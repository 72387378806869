.rs-v {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.ne-r-p {
  max-width: 1000px;
  display: flex;
  padding: 0px;
}
@-moz-document url-prefix() {
  .r-v-cnt {
    scrollbar-width: none;
  }
}

.r-v-cnt {
  position: relative;
  justify-content: flex-start;
  display: flex;
  animation: scroll 30s infinite;
  width: 5000px;
  overflow-x: auto;
  gap: 50px;
  padding: 0px 30px 0px 45px;
}
.r-v-rd {
  flex-shrink: 0;
  display: flex;
  width: 200px;
  padding: 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #4b4f4c;
}
.r-v-icn {
  width: 24px;
  height: 24px;
}

.r-v-xt {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 70px;
}

.r-v-r-m {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
}
.r-v-r-m img {
  background-color: #fff;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}
.r-v-r {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.r-v-cnt::-webkit-scrollbar {
  display: none;
}

@media (min-width: 300px) and (max-width: 767px) {
  .r-v-cnt {
    position: relative;
    justify-content: flex-start;
    padding: 0px 20px 0px 80px;
  }
  .ne-r-p {
    width: 100vw;
    display: flex;
    padding: 10px 0px;
  }
}

@media (max-width: 1280px) and (min-width: 768px) {
  .ne-r-p {
    width: 100vw;
    display: flex;
    padding: 10px 0px;
  }
}

@media (min-width: 3500px) {
  .rs-v {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .ne-r-p {
    max-width: 3500px;
    display: flex;
    padding: 40px 0px;
  }
  .r-v-cnt {
    width: 5000px;
    gap: 100px;
    padding: 0px 30px 0px 100px;
  }
  .r-v-rd {
    width: 600px;
    padding: 40px;
    gap: 44px;
    border-radius: 26px;
  }
  .r-v-icn {
    width: 100px;
    height: 100px;
  }

  .r-v-xt {
    font-size: 40px;
    font-weight: 400;
    height: 250px;
  }

  .r-v-r-m {
    gap: 35px;
  }
  .r-v-r-m img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
  }
  .r-v-r {
    font-size: 40px;
    font-weight: 700;
  }
}
